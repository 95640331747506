import { BehaviorSubject } from "rxjs";
interface TosterInterface {
  position: "top-right" | "bottom-right" | "top-left" | "bottom-left";
  autoDelete: boolean | true;
  toastList: Array<{
    id: number;
    type: "success" | "warn" | "normal" | "error" | "normal-with-btn";
    title?: string;
    message: string;
  }>;
}
const toaster = new BehaviorSubject<TosterInterface | []>([]);
const toasterService = {
  set: (state: any) => {
    let li: any = [];
    const reState = state;
    toaster.asObservable().subscribe((data) => {
      li = data;
    });
    reState.id = li.length + 1;
    li.push(state);
    toaster.next(li);
  },
  get: () => toaster.asObservable(),
  update: (list: any) => {
    toaster.next(list);
  },
};
export default toasterService;
