import { HEADER } from "@constants/images";
import { useState } from "react";
import HeaderItem from "@components/common/HeaderItem.component";
import { LOGOS, ICONS } from "@constants/images";
import { useNavigate } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";

interface IHeaderProps {
  onEcosystemClick: () => void
  onRoadmapClick: () => void
  setSelectedTab(x:any | undefined): any
}

const Header = ({ onEcosystemClick, onRoadmapClick, setSelectedTab }: IHeaderProps) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-logo">
          <img
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
              navigate('/')
            }}
            src={LOGOS.GANGSTAVERSE}
            alt="gangstaaverse-logo"
          />
        </div>
        <div className="navbar-wrapper">

          <div onClick={() => {
            console.log('ecosystem')
            onEcosystemClick()
          }} className="navbar-items">Ecosystem</div>
          {/* <div onClick={() => {
            onEcosystemClick()
            setSelectedTab({
              index: 3,
              title: 'Collectibles'
          })
          }} className="navbar-items">Collectibles</div> */}
          <div onClick={onRoadmapClick} className="navbar-items">Roadmap</div>
          <a className="btn-link" href="https://docs.gangstaverse.co/" target="_blank" rel="noopener noreferrer">
            <div className="navbar-items">Documentation</div>
          </a>
          
        </div>
        <div className="header-social-icons">
          <a href="https://x.com/GangstaVerse" target="_blank" rel="noopener noreferrer">
            <FaXTwitter color="#fff" size={20} />
          </a>
          <a href="https://discord.com/invite/9jkyefW9kU" target="_blank" rel="noopener noreferrer">
            <FaDiscord color="#fff" size={20} />
          </a>
         
        </div>
        <img
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          className="header-burger"
          src={HEADER.BURGER}
          alt="menu"
        ></img>
      </div>
      {showMenu && <HeaderItem showMenu={showMenu} setShowMenu={setShowMenu} />}
    </div>
  );
};

export default Header;
