import { LOGOS } from '@constants/images';

const partnerChains = [
  {
    image: LOGOS.ICON_HERO_LOGO,
    link: 'https://icon.community',
  },
  {
    image: LOGOS.BASE_HERO_LOGO,
    link: 'https://www.base.org/',
  },
  {
    image: LOGOS.SUI_HERO_LOGO,
    link: 'https://sui.io/',
  },
  {
    image: LOGOS.SKALE_HERO_LOGO,
    link: 'https://skale.space/',
  },
  {
    image: LOGOS.ALEO_LOGO,
    link: 'https://aleo.org/',
  },
];

type Props = {
  onEcosystemClick: () => void;
};

function Hero({ onEcosystemClick }: Props) {
  return (
    <div className='hero-section'>
      <div className='hero-content'>
        <h3>
          World of gangsters <br className='hide-md' /> & detectives
        </h3>
        <p>
        GangstaVerse is an ecosystem featuring digital collectibles, games, and events themed around gangsters and detectives. It leverages web3 technology to offer digital asset ownership, strategic gameplay, and rich, immersive storytelling.
        </p>
        <button onClick={onEcosystemClick} className='primary-btn'>
          Explore Ecosystem
        </button>
        <div className='glow-gradient-circle'></div>
      </div>
      <div className='city-bg'>
        <div className='city-background'></div>
        <div className='mid-hero-content'>
          <div className='stats'>
            <div className='stats-items'>
              <h2>15000+</h2>
              <h3>Total Games Played</h3>
            </div>
            <div className='stats-items'>
              <h2>5+</h2>
              <h3>Games</h3>
            </div>
            <div className='stats-items'>
              <h2>1.5m+</h2>
              <h3>Total On-chain Txn</h3>
            </div>
            <div className='stats-items'>
              <h2>$10m+</h2>
              <h3>Total Value Traded</h3>
            </div>
          </div>

          <div className='stats-mb'>
            <div className='stats-mb-wrapper'>
              <div className='stats-items'>
                <h2>15000+</h2>
                <h3>Total Games Played</h3>
              </div>
              <div className='stats-items'>
                <h2>5+</h2>
                <h3>Games</h3>
              </div>
            </div>
            <div className='stats-mb-wrapper'>
              <div className='stats-items'>
                <h2>1.5m+</h2>
                <h3>Total On-chain Txn</h3>
              </div>
              <div className='stats-items'>
                <h2>$10m+</h2>
                <h3>Total Value Traded</h3>
              </div>
            </div>
          </div>

          <div className='partners-chain'>
            {partnerChains.map((item, idx) => (
              <a
                key={idx + ''}
                href={item.link}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={item.image} alt='' />
              </a>
            ))}
          </div>
        </div>
        <div className='moving-glow-gradient'></div>
      </div>
    </div>
  );
}

export default Hero;
