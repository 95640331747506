import React from 'react'

interface IMilestone {
    title: string
    link: string
}

type Props = {
    quarter?: string
    year?: string
    milestones?: IMilestone[]
    index?: number
}

const RoadmapItem = ({quarter, year, milestones, index}:Props) => {
  return (
    <div className='roadmapItem-wrapper'>
        <div className={[index === 1 ? "year" : "year2"].join(' ')}>
            <p>Q{quarter}</p>{' '}
            <span>{year}</span>
        </div>
        <div className="roadmap-milestones">
            <ul>
                {milestones?.map((item, idx) => (
                    item.link !== '' ? (
                        <a key={idx + ''} href={item.link} target="_blank" rel="noopener noreferrer">
                            <li>{item.title}</li>
                        </a>
                    ) : (
                        <li key={idx + ''}>{item.title}</li>
                    )
                    
                ))}
            </ul>
        </div>
    </div>
  )
}

export default RoadmapItem