import { FOOTER } from "@constants/images";
import { LOGOS } from "@constants/images";
import { discord, twitter, telegram, medium } from "../../misc/Links";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  return (
    <footer
      // style={{ backgroundImage: `url(${FOOTER.FOOTER_BG})` }}
      className="footer"
    >
      <p className="logoText">
        {/* Gangsta<span className="logoText-back">verse</span> */}
        <img
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            navigate('/')
          }}
          src={LOGOS.GANGSTAVERSE_FULL}
          alt="gangstaaverse-logo"
        />
      </p>
      <div className="footer-media">
        <button
          onClick={() => window.open(discord, "_blank")}
          type="button"
          className="borderless-btn"
        >
          <img src={FOOTER.DISCORD} alt="footer-discord" />
        </button>
        <button
          onClick={() => window.open(telegram, "_blank")}
          type="button"
          className="borderless-btn"
        >
          <img src={FOOTER.TELEGRAM} alt="footer-telegram" />
        </button>
        <button
          onClick={() => window.open(twitter, "_blank")}
          type="button"
          className="borderless-btn"
        >
          <img src={FOOTER.TWITTER} alt="footer-teitter" />
        </button>
        <button
          onClick={() => window.open(medium, "_blank")}
          type="button"
          className="borderless-btn"
        >
          <img src={FOOTER.MEDIUM} alt="footer-medium" />
        </button>
      </div>
      <p className="copyright"> &#169;2021-{year} GangstaVerse</p>
      <div className="gradient-ellipse"></div>
    </footer>
  );
};

export default Footer;
