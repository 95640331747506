import RoadmapItem from '@components/roadmapItem/RoadmapItem.component';
import React, { useEffect, useRef, useState } from 'react';
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';

type Props = {
    roadmapRef: React.RefObject<HTMLDivElement>
};
const roadmap = [
  {
    year: '2024',
    quarter: '4',
    milestones: [
        {
            title: 'The Battle of Ruby Valley',
            link : 'https://medium.com/@gangstaverse/a-new-chapter-in-gangstaverse-the-battle-of-ruby-valley-ab3a628c66ab'
        },
        {
            title: 'GangWars Multi-Chain Tournament - ICON x SUI',
            link : ''
        },
      
    ],
  },
  {
    year: '2024',
    quarter: '3',
    milestones: [
        {
            title: 'GangWars Multi-Chain Tournament - ICON x BASE',
            link : ''
        }
      
      ,
    ],
  },
  {
    year: '2024',
    quarter: '2',
    milestones: [
        {
            title: 'Grant for GangWars Multichain Tournament - ICON x BASE',
            link : ''
        },
        {
            title: 'Presented Beatdown (now The Battle of Ruby Valley) in SUI Basecamp, Paris',
            link : 'https://x.com/GangstaVerse/status/1778024581900431489'
        }
      
      
    ],
  },
  {
    year: '2024',
    quarter: '1',
    milestones: [
        {
            title: 'GangstaVerse x TOWER: Mob Run',
            link : 'https://gangstaverse.medium.com/a-step-by-step-guide-to-participating-in-gangwars-mob-run-abae534bd179'
        },
        {
            title: 'Presented GangWars in Aleo - Istanbul, Turkey',
            link : 'https://gangstaverse.medium.com/experimental-playground-gangwars-on-aleo-for-advanced-verifiable-game-engine-77eae694d172'
        },
        {
            title: 'Major GangWars Update (including NFT Rental)',
            link : 'https://gangstaverse.medium.com/gangwars-on-icon-major-update-89ab7b749eca'
        },
    ],
  },
  {
    year: '2023',
    quarter: '4',
    milestones: [
        {
            title: 'GangWars: Base',
            link : 'https://gangstaverse.medium.com/announcing-gangwars-on-base-testnet-63ed5602e076'
        },
        {
            title: 'Rock, Paper, Scissors (First Mini-game on Emerald City)',
            link : 'https://gangstaverse.medium.com/rock-paper-scissors-rps-mini-game-4252f842ef26'
        },
    ],
  },
  {
    year: '2023',
    quarter: '3',
    milestones: [
        {
            title: 'The Emerald City Lands',
            link : 'https://gangstaverse.medium.com/land-reveal-the-emerald-city-8f1c1be16559'
        },
        {
            title: 'LootCrates Bridging to Base',
            link : 'https://gangstaverse.medium.com/bridge-your-lootcrates-to-base-9aa0820d2a66'
        },
        {
            title: 'Mob Run: NFT Staking (Season 1)',
            link : 'https://gangstaverse.medium.com/season-1-staking-and-character-generation-in-base-gangwars-ccdfcd8772ac'
        },
    ],
  },
  {
    year: '2023',
    quarter: '2',
    milestones: [
        {
            title: 'NFT Illustration Minting',
            link : 'https://gangstabet.io/illustration-gallery'
        },
        {
            title: 'Major GangWars game update (Leaderboard, Hospitalization, Cooldowns)',
            link : 'https://docs.gangstaverse.co/gangwars/hospitalization-state'
        },
      
      
    ],
  },
  {
    year: '2023',
    quarter: '1',
    milestones: [
        {
            title: 'GangWars Launch on ICON',
            link : 'https://gangstaverse.medium.com/next-step-in-gangstaverse-gangwars-b6100eff9c82'
        },
      
    ],
  },
  {
    year: '2022',
    quarter: '4',
    milestones: [
        {
            title:  'GangstaBet rebrand to GangstaVerse',
            link : 'https://discordapp.com/channels/829608463570173972/883574813011107882/1045378717603598437'
        },
        {
            title: 'Launch of GangstaVerseDAO',
            link : 'https://gangstaverse.medium.com/gangstaversedao-7cb6da886725'
        },
        {
            title: 'NFT Illustration public sale (Sold out!)',
            link : ''
        },
     
      
      
    ],
  },
  {
    year: '2022',
    quarter: '3',
    milestones: [
        {
            title: 'Land Ownership Certificates public sale (Sold out!)',
            link : 'https://gangstaverse.medium.com/a-step-by-step-guide-to-minting-land-ownership-certificate-loc-df7f77d5907f'
        },
        {
            title: 'The Emerald City Map',
            link : 'https://gangstaverse.medium.com/emerald-city-zones-403e4d4d08e0'
        },
        {
            title: 'Bank in Emerald City',
            link : 'https://gangstaverse.medium.com/a-step-by-step-guide-to-depositing-withdrawing-crowns-to-from-the-bank-84b451a46102'
        },
      
      
    ],
  },
  {
    year: '2022',
    quarter: '2',
    milestones: [
        {
            title: 'GOLDEN Keys',
            link : ''
        },
        {
            title: '$CROWN Token Generation Event (TGE)',
            link : 'https://docs.gangstaverse.co/inside-gangstaverse/our-tokens/crown-token'
        },
        {
            title: 'Tales of Cities',
            link : ''
        },
        {
            title: 'Bribe Token release',
            link : 'https://gangstaverse.medium.com/bribe-nfts-d9ba5e4e8ea4'
        },
      
      
      
      
    ],
  },
  {
    year: '2022',
    quarter: '1',
    milestones: [
        {
            title: 'The Emerald City Announcement',
            link : 'https://gangstaverse.medium.com/introducing-the-emerald-city-827b14e9757a'
        },
        {
            title: 'Gangsters & Detective Stories',
            link : 'https://gangstaverse.medium.com/announcing-gangstabet-stories-fb38e47b474'
        }
    ],
  },
  {
    year: '2021',
    quarter: '4',
    milestones: [
        {
            title: 'NFT Minting (Sold out!)',
            link : ''
        },
        {
            title: '$GBET Token Generation Event (TGE)',
            link : 'https://docs.gangstaverse.co/inside-gangstaverse/our-tokens/gbet-token'
        },
        {
            title: 'GangstaVerse Validator in ICON (top 3)',
            link : 'https://gangstaverse.medium.com/launching-gangstabet-p-rep-1-000-000-gg-airdrop-to-stakers-in-november-c0fd73bbd8e0'
        },
      
      
      
    ],
  },
];

const Roadmap = ({roadmapRef}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setIsScrolledToLeft(scrollLeft === 0);
      setIsScrolledToRight(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
      
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
      
    }
  };


  


  return (
    <div ref={roadmapRef} className='roadmap-container'>
      <div className='roadmap-wrapper'>
        <div className='roadmap-header'>
          <h3>GangstaVerse Thus Far</h3>
          {/* <p>
            Suscipit euismod lectus elementum nisl velit. Elit, et lectus tellus
            vestibulum a ultrices ut donec mattis. Pharetra velit, pulvinar
            vivamus in.
          </p> */}
        </div>
        <div className='roadmap-carousel'>
          <div className='roadmap-carousel-wrapper' onScroll={checkScrollPosition} ref={scrollRef}>
            {roadmap.map((item, idx) => (
              <RoadmapItem
                index={idx + 1}
                year={item.year}
                quarter={item.quarter}
                milestones={item.milestones}
              />
            ))}
          </div>
          <div className="glow-gradient-circle"></div>
          <div className='prev-btn-wrapper'>
            <div onClick={scrollLeft} className={['prev-btn', isScrolledToLeft && 'disable-prev'].join(' ')}>
              <MdOutlineKeyboardArrowLeft
                color='#fff'
                size={55}
                fontWeight={700}
              />
            </div>
          </div>
          <div className='next-btn-wrapper'>
            <div onClick={scrollRight} className={['next-btn', isScrolledToRight && 'disable-next'].join(' ')}>
              <MdOutlineKeyboardArrowRight
                color='#fff'
                size={55}
                fontWeight={700}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
