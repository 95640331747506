const gangstabet = "https://gangstabet.io/";
const gbet = "https://docs.gangstabet.io/gangstaverse/gbet-token";
const upgradeNfts = "https://docs.gangstabet.io/tutorials/upgrade-nfts";
const changeName =
  "https://docs.gangstabet.io/gangstaverse/gbet-token/burning-mechanism/name-change";
const creatingStories = "https://docs.gangstabet.io/tutorials/creating-stories";
const emeraldCity = "https://emerald.gangstabet.io/";
const emeraldCityLands =
  "https://docs.gangstabet.io/gangstaverse/the-emerald-city/lands";
const landCertificate = "https://craft.network/collection/land-certificate";
const crown = "https://docs.gangstabet.io/gangstaverse/crown-token";
const emeraldCityGamePlay =
  "https://medium.com/@gangstabet/emerald-city-gameplay-b48d4fbb396d";
const sapphireCity =
  "https://docs.gangstabet.io/extras/faq/the-emerald-city#what-is-the-sapphire-city";
const GangstaBetNFTExpansion =
  "https://medium.com/@gangstabet/emerald-city-introducing-forex-9e1cf4e4ee4f";

const earningCrown =
  "https://docs.gangstabet.io/gangstaverse/crown-token/earning-usdcrown";

const bank = "https://emerald.gangstabet.io/bank";
const GangstaBetP_REP =
  "https://docs.gangstabet.io/tutorials/earn-usdcrown/voting-gangstabet-prep#how-to-delegate-icx-votes-to-gangstabet-p-rep";
const gbetBnusd =
  "https://docs.gangstabet.io/tutorials/earn-usdcrown/adding-liquidity-supply#how-to-add-liquidity";
const crownBnUsd = "";
const discord = "https://discord.com/invite/9jkyefW9kU";
const twitter = "https://twitter.com/GangstaVerse";
const telegram = "https://t.me/gangstaverse";
const medium = "https://gangstaverse.medium.com/";
const marketplaceGangstaBet = "https://gangstabet.io/marketplace";
const marketplaceCraft = "https://craft.network/collection/gangstabet";
const gangstabetProfile = "https://gangstabet.io/profile/";

const EMERALD_CITY_PRODUCTS = {
  CIVILIANS: "https://docs.gangstabet.io/learn/civilians",
  GOLDENKEY:
    "https://docs.gangstabet.io/gangstaverse/the-emerald-city/golden-keys",
  BANK: "https://gangstabet.medium.com/a-step-by-step-guide-to-depositing-withdrawing-crowns-to-from-the-bank-84b451a46102",
  LOC: "https://gangstabet.medium.com/the-emerald-city-lands-and-land-ownership-certificates-loc-86978a795bcd",
  FOREX:
    "https://gangstabet.medium.com/emerald-city-introducing-forex-9e1cf4e4ee4f",
  BRIBE: "https://gangstabet.medium.com/bribe-nfts-d9ba5e4e8ea4",
};

export {
  gangstabetProfile,
  marketplaceGangstaBet,
  marketplaceCraft,
  discord,
  twitter,
  telegram,
  medium,
  gangstabet,
  gbet,
  upgradeNfts,
  changeName,
  creatingStories,
  emeraldCity,
  emeraldCityLands,
  landCertificate,
  crown,
  emeraldCityGamePlay,
  sapphireCity,
  GangstaBetNFTExpansion,
  earningCrown,
  bank,
  GangstaBetP_REP,
  gbetBnusd,
  crownBnUsd,
  EMERALD_CITY_PRODUCTS,
};
