import Hero from "./Hero";
import Ecosystem from "./Ecosystem";
import Partners from "./Partners";
import Roadmap from "./Roadmap";
import Footer from "@components/common/Footer.component";


interface IHomeProps {
  ecosystemRef: React.RefObject<HTMLDivElement>
  roadmapRef: React.RefObject<HTMLDivElement>
  onEcosystemClick: () => void
  selectedTab?: any
  setSelectedTab(x:any | undefined): any
}

const Home = ({ ecosystemRef, roadmapRef, onEcosystemClick, selectedTab, setSelectedTab }:IHomeProps) => {
  return (
    <div className="homepage-wrapper">
      <Hero onEcosystemClick={onEcosystemClick} />
      <Ecosystem selectedTab={selectedTab} setSelectedTab={setSelectedTab} ecosystemRef={ecosystemRef} />
      <Partners/>
      <Roadmap roadmapRef={roadmapRef} />
      <Footer />
    </div>
  );
};

export default Home;
