import { WALLET_TYPE } from "@enum/walletType.enum";
import { createContext } from "react";


export interface IWalletConnectionContext {
  address: string | null;
  type: WALLET_TYPE;
}

const initialValue = {
  address: null,
  type: WALLET_TYPE.NONE,
};

export const walletContext = createContext<IWalletConnectionContext>(
  initialValue
);
