import React, { ReactNode } from 'react'

type Props = {
    children: ReactNode,
    titleImage: string,
    bgImage: string,
    handleClick:(x: any) => void,
    selected: boolean,
}

const EcosystemCardWrapper = ({children, bgImage, titleImage, selected, handleClick}: Props) => {
  return (
    <div onClick={handleClick}  className={['ecosystem-card-wrapper', selected && 'selected'].join(' ')} style={{ background: `url(${bgImage})`, backgroundSize: titleImage === '' ? 'contain' : 'cover' }}>
        <div className={["ecosystem-card-content"].join(' ')}>
            {titleImage !== '' && (
                <img className='card-title-image' src={titleImage} alt="" />
            )}
            
            {children}
        </div>
        
    </div>
  )
}

export default EcosystemCardWrapper