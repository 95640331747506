import { PARTNERS } from '@constants/images'
import React from 'react'

type Props = {}

const partners = [
    {
        image: PARTNERS.ICON_FOUNDATION_PARTNER,
        link: 'https://www.icon.foundation/'
    },
    {
        image: PARTNERS.ICON_CPS_PARTNER,
        link: 'https://cps.icon.community/'
    },
    {
        image: PARTNERS.CRAFT_PARTNER,
        link: 'https://craft.network/'
    },
    {
        image: PARTNERS.ANIMOCA_PARTNER,
        link: 'https://www.animocabrands.com/'
    },
    {
        image: PARTNERS.TOWER_PARTNER,
        link: 'https://towerecosystem.com/'
    },
    {
        image: PARTNERS.BALANCED_PARTNER,
        link: 'https://balanced.network/'
    },
]

const Partners = (props: Props) => {
  return (
    <div className='partner-container'>
        <div className="partner-wrapper">
            <div className="partner-header">
                <h3>our partners</h3>
                <p>Our collaborations are vital in expanding the scope and depth of the GangstaVerse, bringing cutting-edge technology and fresh content to our community. Together, we are building a more engaging and expansive digital world.</p>
            </div>
            <div className="partners">
                {partners.map((item, idx) => (
                    <a className='partners-item' key={idx + ''} href={item.link} target="_blank" rel="noopener noreferrer">
                        <img src={item.image} alt="" />
                    </a>
                ))}
            </div>
            
        </div>

    </div>
  )
}

export default Partners