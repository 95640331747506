import { BASE_CHAIN_LOGO, ICON_CHAIN_LOGO, ECOSYSTEM, LOGOS, ICONS } from "@constants/images";

interface IBulltetPoints {
    key: string
    value: string
  }

interface ISocialLinks {
    image: string,
    link: string
}
export interface IEcosystemData {
    index: number | string,
    title: string,
    chain: string[],
    discordLik: string,
    xLink: string,
    description: string[],
    externalLink: string,
    chainLogo: string[],
    type: string,
    bgImage: string,
    titleImage: string,
    bulletPoints?: IBulltetPoints[]
    socialLinks?: ISocialLinks[]

}

export const ecosystemData : IEcosystemData[] = [
    {
        index: 5,
        title: 'Gangstabet',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["GangstaBet is a collection of 5,555 unique Gangsters and Detectives living in the ICON blockchain. GangstaBet NFTs can be upgraded by burning $GBET tokens, which are distributed daily to holders. Owners can create unique character names, increase level & stats and add text stories to their NFTs. The supply of $GBET will decrease periodically and it will stop after 9 years."],
        externalLink: 'https://gangstabet.io/',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'collectibles',
        bgImage: ECOSYSTEM.GANGSTABET_BG,
        titleImage: ECOSYSTEM.GANGSTABET_BG_TITLE,
        socialLinks: [
            { 
                image: ICONS.DISCORD_WHITE, 
                link:'https://discord.com/invite/9jkyefW9kU' 
            },
            { 
                image: ICONS.X_ICON_WHITE, 
                link:'https://x.com/GangstaVerse' 
            },
        ]
    },
    {
        index: 3,
        title: 'The Battle Of Ruby Valley',
        chain: ['SUI'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["The Battle of Ruby Valley is a strategic NFT-based game where players join factions inspired by real-world crime syndicates. Players mint characters, participate in active and passive battles, and strategically stake faction tokens to increase their faction's control over the valley. The game features unique tokens for each faction and a governance token (CROWN) for strategic actions like attacks and defenses. The goal is to dominate the valley and improve token conversion ratios. Rich lore, missions, and community engagement enhance the immersive experience.", "The game features dynamic NFTs that evolve with gameplay, allowing for active and passive battles to level up stats and earn rewards."],
        externalLink: 'https://therubyvalley.com',
        chainLogo: [ECOSYSTEM.SUI_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.GANGWAR_RUBY_BG,
        titleImage: ECOSYSTEM.RUBY_TITLE,
        bulletPoints: [
            {
                key: "Unique Factions",
                value : "Join different factions, each with their own tokens." 
            },
            {
                key:  "Character NFTs",
                value: "Mint dynamic NFTs that evolve with gameplay."
            },
            {
                key: "Active and Passive Battles",
                value : "Fight to level up stats and earn tokens, even when not actively playing."
            },
            {
                key: "Faction Control",
                value: "Get control of Ruby Valley by battles, staking and various strategic gameplays."
            }
        ],
        socialLinks: [
            { 
                image: ICONS.X_ICON_WHITE, 
                link:'https://x.com/RubyValleyGame' 
            },
        ]

    },
    {
        index: 9,
        title: 'GangWars Multi-chain Tournament',
        chain: ['ICON','BASE','SUI'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["The GangWars Tournament is a strategy game competition where players from the ICON, Base, and SUI blockchains use NFT characters in turn-based battles. Participants form teams and compete in group stages, with the top teams advancing to a knockout bracket.", "Players form teams of 4 or 5 and participate in turn-based battles, strategizing with unique character abilities. The tournament begins with separate group stages for each blockchain, with each team playing 8 matches. The top 8 teams from each group advance to a knockout bracket, competing for prizes. The prize pool, funded by entry fees and Foundation grant, grows with the number of participants, offering substantial rewards for the winners."],
        externalLink: '',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO, ECOSYSTEM.BASE_PLAIN_LOGO,ECOSYSTEM.SUI_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.MULTICHAIN_BG,
        titleImage: ECOSYSTEM.MULTICHAIN_TITLE,
        socialLinks: [
            { 
                image: ICONS.X_ICON_WHITE, 
                link:'https://x.com/PlayGangWars' 
            },
        ]
    },
    {
        index: 1,
        title: 'GangWars',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'x',
        description: ['GangWars is a thrilling strategic semi-passive game set in the sprawling underworld of The Emerald City. Players take command of GangstaBets, battling for valuable resources such as LootCrates, CROWN, and ICX. To gain the upper hand, players can equip their characters with an arsenal of weapons, items, and sneak moves, allowing for a wide range of tactical possibilities.', 'Though it’s just a glimpse of the full Emerald City experience, Gangwars packs enough punch to keep players hooked into its engaging and immersive gameplay.'],
        externalLink: 'https://war.gangstaverse.co/',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.GANGWAR_ICON_BG,
        titleImage: ECOSYSTEM.GANGWAR_ICON_TITLE,
        socialLinks: [
            { 
                image: ICONS.X_ICON_WHITE, 
                link:'https://x.com/PlayGangWars' 
            },
        ]

    },
    {
        index: 2,
        title: 'GangWars',
        chain: ['BASE'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["This version of GangWars runs on the Base blockchain, offering intense battles and gameplay. Players can start with two free characters and hire more as they progress. Equip unique items and unlock loot crates, all without needing an NFT. The Mob Run mode allows players to automate gameplay and earn rewards over a 14-day period by maintaining a minimum TOWER token balance.", "The Mob Run mode allows players to automate gameplay and earn rewards over a 14-day period by maintaining a minimum TOWER token balance."],
        externalLink: 'https://base.gangstaverse.co/',
        chainLogo: [ECOSYSTEM.BASE_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.GANGWAR_BASE_BG,
        titleImage: ECOSYSTEM.GANGWAR_BASE_TITLE,
        socialLinks: [
            { 
                image: ICONS.X_ICON_WHITE, 
                link:'https://x.com/PlayGangWars' 
            },
        ]
    },

    {
        index: 4,
        title: 'The Emerald City',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["Emerald City is the first virtual gangster city to be launched as part of the GangstaVerse. A DeFi game offering a rich storyline, advanced NFTs, and various DeFi components like staking and yield farming. Players can buy lands, earn $CROWN tokens, and engage in dynamic gameplay for land control. ", "With 11,111 unique city blocks available, players can acquire and develop land properties, leveraging their assets for strategic advantages. The game allows players to earn $CROWN tokens through a variety of in-game activities. "],
        externalLink: 'https://map.emeraldcity.xyz/',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.EMERALD_BG,
        titleImage: ECOSYSTEM.EMERALD_BG_TITLE,
        socialLinks: [
        ]
    },
    {
        index: 10,
        title: 'Gangster Pugs',
        chain: ['TELEGRAM'],
        discordLik: 'discord',
        xLink: 'x',
        description: ['Gangster Pugs is a web3-enabled Telegram game that blends strategy with ongoing progression. Players lead their team to defeat Pugs residing in the City, collecting gold and leveling up as they go. The game features challenging boss battles, and players can earn tokens with their web3 wallets.'],
        externalLink: '',
        chainLogo: [LOGOS.TELEGRAM_LINE],
        type: 'games',
        bgImage: ECOSYSTEM.GANGSTA_PUGS_BG,
        titleImage: '',
        socialLinks: [
        ]

    },
    
    
    {
        index: 8,
        title: 'Rock, Paper & Scissors',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["Rock, Paper & Scissors (RPS) is a game set in The Emerald City where players can wager ICX or CROWN in classic RPS matches with a dark twist. Players can challenge friends or strangers, with the rules following the traditional format. The game features a leaderboard with real-time stats and weekly rounds, allowing players to track progress and earn rewards. Participants can also build their profile and earn respect, adding a competitive edge."],
        externalLink: 'https://map.emeraldcity.xyz/game/rps?r=play',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'games',
        bgImage: ECOSYSTEM.ROCK_PAPER_SCISSORS_BG,
        titleImage: ECOSYSTEM.ROCK_PAPER_SCISSORS_TITLE,
        bulletPoints: [
            {
                key: "Simple Gameplay",
                value: "Host or join games with ICX or CROWN wagers." 
            },
            {
                key: "Rules",
                value : "Rock breaks Scissors, Scissors cuts Paper, Paper captures Rock."
            },
            {
                key: "Stats and Leaderboard",
                value : "Weekly rounds with real-time stats and rewards."
            },
            {
                key: "Profile and Respect",
                value : "Track your progress, earn respect, and build your legacy."
            }
        ],
        socialLinks: [
        ]

    },
    
    
    
    {
        index: 7,
        title: 'The Emerald City Lands',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'x',
        description: ["Emerald City is the first major gangster city in the GangstaVerse, launched on the ICON blockchain. This city encompasses 11,111 land NFTs distributed across multiple districts, where NFTs will compete for control and dominance.", "There are a total of 11,111 city blocks representing an area of 70m x 70m per block and can consist of one or many buildings. The entire City is divided into several regions like Commercial Area, Government Area, Vacation Town, Airport, Shopping Area, Residential Area, Park, Apartments, Suburbs, and so on. There will be several activities and events in the lands."],
        externalLink: 'https://emeraldcity.gangstaverse.co/lands',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'collectibles',
        bgImage: ECOSYSTEM.EMERALD_BG,
        titleImage: ECOSYSTEM.EMERALD_BG_TITLE,
        socialLinks: [
        ]
    },
    {
        index: 6,
        title: 'GangstaVerse DAO',
        chain: ['ICON'],
        discordLik: 'discord',
        xLink: 'https://governance.gangstaverse.co',
        description: ["A governance system that allows the community to participate in decision-making for the GangstaVerse ecosystem. CROWN and xCROWN tokens enable holders to vote on CPS funding proposals, ecosystem fund allocations, and major decisions."],
        externalLink: 'https://governance.gangstaverse.co/',
        chainLogo: [ECOSYSTEM.ICON_PLAIN_LOGO],
        type: 'daos',
        bgImage: ECOSYSTEM.GANGSTAVERSE_BG,
        titleImage: ECOSYSTEM.GANGSTAVERSE_DAO_TITLE,
        socialLinks: [
        ]
    },
    
]
