import { ICONS } from '@constants/images';
import React, { useState } from 'react';
import { ecosystemData, IEcosystemData } from './EcosystemData';
import EcosystemCardWrapper from '@components/ecosystemCardWrapper/EcosystemCardWrapper.component';
import { AnimatePresence, Variants, motion } from 'framer-motion'

type Props = {
    ecosystemRef: React.RefObject<HTMLDivElement>
    selectedTab?: any
    setSelectedTab(x:any): any
};

const menu = [
    {
        index: 1,
        title: 'All'
    },
    {
        index: 2,
        title: 'Games'
    },
    {
        index: 3,
        title: 'Collectibles'
    },
    {
        index: 4,
        title: 'DAOs'
    },
]

const tabContentVariants: Variants = {
	initial: {
		y: 10,
		opacity: 0
	},
	enter: {
		y: 0,
		opacity: 1
	},
	exit: {
		y: -10,
		opacity: 0
	}
}

const Ecosystem = ({ ecosystemRef, setSelectedTab, selectedTab }: Props) => {
    // const [selectedTab, setSelectedTab] = useState(menu[0])
    const [selectedProduct, setSelectedProducts] = useState<IEcosystemData | any>(selectedTab.index === 1
        ? ecosystemData[0]
        : ecosystemData.filter(item => item.type.toLowerCase() === selectedTab.title.toLowerCase())[0])

    const filteredData : IEcosystemData[] = selectedTab.index === 1
        ? ecosystemData
        : ecosystemData.filter(item => item.type.toLowerCase() === selectedTab.title.toLowerCase());

    const handleTabClick = (tab: any) => {
        setSelectedTab(tab)
        const _filtered = tab.index === 1
        ? ecosystemData
        : ecosystemData.filter(item => item.type.toLowerCase() === tab.title.toLowerCase());
        setSelectedProducts(_filtered[0])

    }

    const handleEcosystemClick = (ecosystem: any) => {
        setSelectedProducts(ecosystem)
    }

  return (
    <div ref={ecosystemRef} className='ecosystem-container'>
      <div className='ecosystem-wrapper'>
        <div className='ecosystem-header'>
          <h3>GangstaVerse Ecosystem</h3>
          <p>
            GangstaVerse is an interconnected multi-chain ecosystem offering gaming, digital collectibles, and a DAO for community-driven governance. It spans multiple blockchains, creating a seamless experience and community engagement in a gangster and detective-themed universe.
          </p>
        </div>
        <div className="ecosystem-ctx">
            <div className="ecosystem-ctx-menu">
                {menu.map((item, idx) => (
                    <div onClick={() => handleTabClick(item)} key={idx + item.index} className={['ecosystem-ctx-menu-item', selectedTab.index === item.index && 'active-tab'].join(' ')}>{item.title}</div>
                ))}
                
            </div>
            <AnimatePresence mode='wait'>
            <motion.div
            	key={selectedTab?.index || "empty"}
                variants={tabContentVariants}
                initial="initial"
                animate="enter"
                exit="exit"
                transition={{
                    duration: .3
                }}
                className="ecosystem-tabs-ctx"
            >
                <div className='ecosystem-tabs-ctx-items'>
                       {filteredData.map((item, idx) => (
                        <EcosystemCardWrapper
                            key={item.index}
                            bgImage={item.bgImage}
                            titleImage={item.titleImage}
                            handleClick={() => handleEcosystemClick(item)}
                            selected={selectedProduct.index === item.index}
                        >
                            <div className="ecosystem-chains">
                                {item.chainLogo.map((chainItm, chainIdx) => (
                                    <div className={['ecosystem-chain-logos', item.chain[chainIdx]].join(' ')}>
                                        <img key={chainIdx + ''} src={chainItm} alt="" />
                                    </div>
                                    
                                ))}
                            </div>
                        </EcosystemCardWrapper>
                       ))}
                </div>
                {Object.keys(selectedProduct).length > 0 && (
                    <motion.div 
                        key={selectedProduct?.title || "empty"}
                        variants={tabContentVariants}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        transition={{
                            duration: .3
                        }}
                        className='ecosystem-tabs-ctx-info'
                    >
                        <div className="tabs-social-logo">
                            {selectedProduct?.socialLinks?.map((item:any, idx: number) => (
                                <a key={idx + ''} className='btn-link' href={item?.link} target="_blank" rel="noopener noreferrer">
                                    <img src={item?.image} alt="" />
                                </a>
                            ))}
                        </div>
                        <div className="tabs-info-content">
                            <h3>{selectedProduct?.title}</h3>
                            {selectedProduct?.description?.map((item: string, idx: number) => (
                                <p key={idx}>{item}</p>
                            ))}

                            {selectedProduct?.bulletPoints && (
                                <ul className='tabs-content-bullet'>
                                    {selectedProduct?.bulletPoints?.map((item: any, idx: number) => (
                                        <li key={idx + ''}>
                                            <span>{item?.key}</span>
                                            <p>{item?.value}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            
                            {selectedProduct?.externalLink !== '' ? (
                                <a className='btn-link' href={selectedProduct?.externalLink} target="_blank" rel="noopener noreferrer">
                                <button  className='primary-btn'> Visit Website</button>
                            </a>
                            ) : (
                                <button disabled  className='primary-btn'>Under Development</button>
                            )}
                            
                            
                        </div>
                    </motion.div>
                )}
                
            </motion.div>
            </AnimatePresence>
            
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
