import { AiOutlineClose } from "react-icons/ai";
import Footer from "@components/common/Footer.component";
import { LOGOS } from "@constants/images";
import { useRef, useEffect } from "react";
import {
  gangstabet,
  gbet,
  upgradeNfts,
  changeName,
  creatingStories,
  emeraldCity,
  emeraldCityLands,
  landCertificate,
  crown,
  emeraldCityGamePlay,
  sapphireCity,
  GangstaBetNFTExpansion,
  earningCrown,
  bank,
  GangstaBetP_REP,
  gbetBnusd,
  crownBnUsd,
} from "@misc/Links";

import { useNavigate } from "react-router-dom";

const HeaderItem = ({ setShowMenu, showMenu }: any) => {
  const navigate = useNavigate();
  const menuRef = useRef<any>();

  useEffect(() => {
    const handler = (event: any) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="main-menu-item-wraper ">
      <div
        className={`menuItems wow animate__animated ${
          showMenu ? "animate__fadeInRight" : "animate__backOutRight"
        }`}
        ref={menuRef}
      >
        <div className="menuItems-logo">
          <AiOutlineClose
            onClick={() => {
              setShowMenu(false);
            }}
            className="close"
            size={27}
          />
        </div>

        <div className="menuItems-allItems">
          <div className="navbar-wrapper-mb">
            <div className="navbar-items">Ecosystem</div>
            {/* <div className="navbar-items">Collectibles</div> */}
            <div className="navbar-items">Roadmap</div>
            <div className="navbar-items">Documentation</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderItem;
