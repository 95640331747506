import { createContext } from "react";

export interface ILedgerContext {
  ledgerTransport: any | null;
  ledgerPath: any | null;
  setLedgerInfo: any;
}

const initialValue = {
  ledgerTransport: null,
  ledgerPath: null,
  setLedgerInfo: () => {},
};

export const ledgerContext = createContext<any>(initialValue);
