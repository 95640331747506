import { useState, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@assets/styles/main.style.scss";
import { WALLET_TYPE } from "@enum/walletType.enum";
import {
  IWalletConnectionContext,
  walletContext,
} from "@src/core/context/walletContext";
import { ILedgerContext, ledgerContext } from "@src/core/context/ledgerContext";

const AppWrapper = (props: any) => {
  const [walletConnection, setWalletConnection] = useState<
    IWalletConnectionContext
  >({
    address: null,
    type: WALLET_TYPE.NONE,
  });

  const [ledgerInfo, setLedgerInfo] = useState<any>({
    ledgerPath: null,
    ledgerTransport: null,
  });

  const updateWalletConnection = ({
    address,
    type,
  }: IWalletConnectionContext) => {
    setWalletConnection({ address, type });
  };

  const eventHandler = (event: any) => {
    console.log(event.detail);
    if (event.detail.type === "RESPONSE_ADDRESS") {
      // Wallet connected event
      if (event.detail.payload) {
        console.log("Connected!", event.detail.payload);
        updateWalletConnection({
          address: event.detail.payload,
          type: WALLET_TYPE.ICON,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("ICONEX_RELAY_RESPONSE", eventHandler, false);
    return () => {
      window.removeEventListener("ICONEX_RELAY_RESPONSE", eventHandler);
    };
  }, []);

  // useEffect(() => {
  //   console.log("rendering after triggerring index");
  //   console.log(ledgerInfo);
  // }, [ledgerInfo]);

  return (
    <walletContext.Provider value={walletConnection}>
      <ledgerContext.Provider value={{ ledgerInfo, setLedgerInfo }}>
        {props.children}
      </ledgerContext.Provider>
    </walletContext.Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AppWrapper>
    <App />
  </AppWrapper>
);
