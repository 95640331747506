import { useState, useEffect, createContext, useRef } from "react";
import Header from "@components/common/Header.component";
import Footer from "@components/common/Footer.component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EmeraldCity from "@pages/product/EmeraldCity.product";
import Gangstabet from "@pages/product/Gangstabet.product";
import WalletModal from "@components/modal/WalletModal.component";
import Toaster from "@components/common/toaster/Toaster.component";
import toasterService from "@components/common/toaster/ToasterService.component";

//Test Code
import IllustrationHome from "@components/illustration/Home.component";
import Home from "@pages/homepage/Home.page";

const ecosystemTabMenu = [
  {
      index: 1,
      title: 'All'
  },
  {
      index: 2,
      title: 'Games'
  },
  {
      index: 3,
      title: 'Collectibles'
  },
  {
      index: 4,
      title: 'DAOs'
  },
]

export default function App() {
  const [toasterList, setToasterList] = useState([]);
  const ecosystemRef = useRef<HTMLDivElement>(null)
  const roadmapRef = useRef<HTMLDivElement>(null)
  const [selectedTab, setSelectedTab] = useState(ecosystemTabMenu[0])


  const scrollToEcosystem = () => {
    if (ecosystemRef.current) {
      const offsetTop = ecosystemRef.current.getBoundingClientRect().top + window.pageYOffset;
      const headerOffset = 70; 
      window.scrollTo({
        top: offsetTop - headerOffset,
        behavior: 'smooth'
      });
    }
  }
  const scrollToRoadmap = () => {
    if (roadmapRef.current) {
      const offsetTop = roadmapRef.current.getBoundingClientRect().top + window.pageYOffset;
      const headerOffset = 70; 
      window.scrollTo({
        top: offsetTop - headerOffset,
        behavior: 'smooth'
      });
    }
  }


  useEffect(() => {
    toasterService.get().subscribe((data: any) => {
      setToasterList(data);
    });
  });
  return (
    <BrowserRouter>
      <Header setSelectedTab={setSelectedTab} onRoadmapClick={scrollToRoadmap} onEcosystemClick={scrollToEcosystem} />
      <Routes>
        <Route path="/" element={<Home selectedTab={selectedTab} setSelectedTab={setSelectedTab} onEcosystemClick={scrollToEcosystem} roadmapRef={roadmapRef} ecosystemRef={ecosystemRef} />} />
        {/* <Route path="/emerald-city" element={<EmeraldCity />} />
        <Route path="/gangstabet" element={<Gangstabet />} />
        <Route path="/mint" element={<IllustrationHome />} /> */}
      </Routes>
      {/* <Footer backgroundColor="black" /> */}
      <Toaster position="bottom-right" autoDelete toastList={toasterList} />
    </BrowserRouter>
  );
}
