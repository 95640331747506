import Burger from "@assets/images/common/burger.svg";
import Icon from "@assets/images/common/icon.svg";
import LandBg from "@assets/images/common/land-bg.png";
import Twitter from "@assets/images/footer/twitter.svg";
import Discord from "@assets/images/footer/discord.svg";
import telegram from "@assets/images/footer/telegram.svg";
import medium from "@assets/images/footer/medium.svg";
import icon from "@assets/images/footer/icon.svg";
import emeraldCity from "@assets/images/gangstaverse/emeraldCity.svg";
import gangstabet from "@assets/images/gangstaverse/gangstabet.svg";
import gangwar from "@assets/images/gangstaverse/gangwar.png";
import gangwarBanner from "@assets/images/gangstaverse/gangwarBanner.png";
import X_ICON from "@assets/images/logo/x_icon.png";
import DISCORD_ICON from "@assets/images/logo/discord_icon.png"
import DISCORD_WHITE from "@assets/images/logo/discord_white.png"
import X_ICON_WHITE from "@assets/images/logo/X.png"

import sapphireCity from "@assets/images/gangstaverse/sapphireCity.svg";
import dao from "@assets/images/gangstaverse/dao.svg";
import gangstabetLogo from "@assets/images/logo/gangstabet.svg";
import landTop from "@assets/images/gangstaverse/lands_top_row.png";
import landMiddle from "@assets/images/gangstaverse/lands_middle_row.png";
import barBg from "@assets/images/parallax/bar-bg.svg";
import fenceBg from "@assets/images/parallax/fence-bg.svg";
import mainBg from "@assets/images/parallax/main-bg.svg";
import gangstaverseLogo from "@assets/images/logo/gangstaverse.svg";
import gangstaverseLogoAlt from "@assets/images/logo/gangstaverse_alt.svg";
import FooterBg from "@assets/images/footer/footerbg.png";

//icons marketplace
import gangstabetHat from "@assets/images/logo/gangstabetHat.png";
import craft from "@assets/images/logo/craft.png";
import gangwarLogo from "@assets/images/logo/gangwar.png";

//illustration
import IllustrationSampleNft from "@assets/images/illustration/illustrationMintNft.svg";
import IllustrationSampleNftPJ from "@assets/images/illustration/pj_img_2.png";
import TransparentLayer from "@assets/images/illustration/transparentLayer.svg";

// carousel images
import BottomLeftOne from "@assets/images/showPiece/one/BottomLeft_1.webp";
import BottomRightOne from "@assets/images/showPiece/one/BottomRight_1.webp";
import TopLeftOne from "@assets/images/showPiece/one/TopLeft_1.webp";
import TopRightOne from "@assets/images/showPiece/one/TopRight_1.webp";
import BottomLeftTwo from "@assets/images/showPiece/two/BottomLeft_2.webp";
import BottomRightTwo from "@assets/images/showPiece/two/BottomRight_2.webp";
import TopLeftTwo from "@assets/images/showPiece/two/TopLeft_2.webp";
import TopRightTwo from "@assets/images/showPiece/two/TopRight_2.webp";

import BottomLeftThree from "@assets/images/showPiece/three/BottomLeft.webp";
import BottomRightThree from "@assets/images/showPiece/three/BottomRight.webp";
import TopLeftThree from "@assets/images/showPiece/three/TopLeft.webp";
import TopRightThree from "@assets/images/showPiece/three/TopRight.webp";

import BottomLeftFour from "@assets/images/showPiece/four/BottomLeft.webp";
import BottomRightFour from "@assets/images/showPiece/four/BottomRight.webp";
import TopLeftFour from "@assets/images/showPiece/four/TopLeft.webp";
import TopRightFour from "@assets/images/showPiece/four/TopRight.webp";

import BottomLeftFive from "@assets/images/showPiece/five/BottomLeft.webp";
import BottomRightFive from "@assets/images/showPiece/five/BottomRight.webp";
import TopLeftFive from "@assets/images/showPiece/five/TopLeft.webp";
import TopRightFive from "@assets/images/showPiece/five/TopRight.webp";

import CarouselNextIcon from "@assets/images/extra/carouselNextIcon.svg";

import EmeraldInfoBg from "@assets/images/extra/emeraldInfoBg.png";
import gangster from "@assets/images/extra/gangsters.svg";
import carouselOne from "@assets/images/extra/carouselOne.webp";
import carouselTwo from "@assets/images/extra/carouselTwo.webp";
import carouselThree from "@assets/images/extra/CarouselThree.webp";
import carouselFour from "@assets/images/extra/carouselFour.webp";
import spotlight from "@assets/images/common/spotlight.png";
import CharacterBg1 from "@assets/images/gangstaverse/character1.png";
import CharacterBg2 from "@assets/images/gangstaverse/character2.png";

import LandViewer from "@assets/images/extra/landViewer.svg";

//decorator
import Decorator from "@assets/images/common/boxEdge.svg";
import plainSep from "@assets/images/common/plainSeperator.svg";
import stylishSep from "@assets/images/common/line.svg";
import plainDecorEdge from "@assets/images/common/plainEdge.svg";
import verticalLine from "@assets/images/common/verticalLine.svg";
import mintBoxSep from "@assets/images/common/mintBoxSep.svg";
import modalSeperator from "@assets/images/extra/modal_seperator.svg";

//timeline
import Bullet from "@assets/images/extra/bullet.svg";

//layers
import Layer1 from "@assets/images/parallax/layer1.png";
import Layer2 from "@assets/images/parallax/layer2.png";
import Layer3 from "@assets/images/parallax/layer3.png";
import Layer4 from "@assets/images/parallax/layer4.png";
import Layer5 from "@assets/images/parallax/layer5.png";

import emeraldCityLogoProduct from "@assets/images/emeraldCity/logo.svg";
import crownLogo from "@assets/images/emeraldCity/crown.svg";
import spinnerCrown from "@assets/images/emeraldCity/spinner.svg";
import ecBanner from "@assets/images/emeraldCity/banner.svg";

import gangstabetLogoProduct from "@assets/images/gangstabet/logo.svg";
import hatLogo from "@assets/images/gangstabet/hat.svg";
import spinnerHat from "@assets/images/gangstabet/spinner.svg";

import gbBanner from "@assets/images/gangstabet/banner.svg";
import CROWN from "@assets/images/logo/crown.svg";

//product
import bank from "@assets/images/products/bank.svg";
import civilians from "@assets/images/products/civilians.svg";
import goldenKey from "@assets/images/products/goldenKey.svg";
import loc from "@assets/images/products/loc.svg";

//story
import story1 from "@assets/images/stories/story1.svg";
import story2 from "@assets/images/stories/story2.svg";
import story3 from "@assets/images/stories/story3.svg";
import story4 from "@assets/images/stories/story4.svg";
import storyicon from "@assets/images/stories/storyicon.svg";
import detective from "@assets/images/stories/detective.png";

import agoraLogo from "@assets/images/logo/agora.svg";
import emeraldCityLogo from "@assets/images/logo/emeraldCity.svg";
import agoraBanner from "@assets/images/banner/agora.svg";
import gangstabetBanner from "@assets/images/banner/gangstabet.svg";
import gangstaverseText from "@assets/images/logo/gangstaverseText.svg";

//mint
import iconbtn from "@assets/images/common/iconbtn.svg";
import ledgerbtn from "@assets/images/common/ledgerbtn.svg";
import nftholder from "@assets/images/common/nftholder.svg";

//toaster
import errorIconToaster from "@assets/images/toaster/error_icon.png";
import successIconToaster from "@assets/images/toaster/success_icon.png";
import warnIconToaster from "@assets/images/toaster/warn_icon.png";
import closeToaster from "@assets/images/toaster/close_icon.svg";

// partners for hero section
import ICON_HERO_LOGO from "@assets/images/logo/icon_hero.png"
import BASE_HERO_LOGO from "@assets/images/logo/base_hero.png"
import SKALE_HERO_LOGO from "@assets/images/logo/skale_hero.png"
import SUI_HERO_LOGO from "@assets/images/logo/sui_hero.png"

// partners
import ICON_FOUNDATION_PARTNER from "@assets/images/logo/icon_foundation_partner.png"
import ICON_CPS_PARTNER from "@assets/images/logo/icon_cps_partner.png"
import CRAFT_PARTNER from "@assets/images/logo/craft_partner.png"
import ANIMOCA_PARTNER from "@assets/images/logo/animoca_partner.png"
import TOWER_PARTNER from "@assets/images/logo/tower_partner.png"
import BALANCED_PARTNER from "@assets/images/logo/balanced_partner.png"

// ecosystem
import ICON_CHAIN_LOGO from "@assets/images/logo/icon_chain.png"
import BASE_CHAIN_LOGO from "@assets/images/logo/base_chain.png"
import ICON_PLAIN_LOGO from "@assets/images/logo/icon_plain_logo.png"
import BASE_PLAIN_LOGO from "@assets/images/logo/base_plain_logo.png"
import SUI_PLAIN_LOGO from "@assets/images/logo/sui_logo.png";
import ALEO_LOGO from "@assets/images/logo/aleo.svg"

import GANGWAR_ICON_BG from "@assets/images/ecosystem/gangwar-icon.png"
import GANGWAR_ICON_TITLE from "@assets/images/ecosystem/gangwar-title-icon.png"
import GANGWAR_BASE_BG from "@assets/images/ecosystem/gangwar-base.png"
import GANGWAR_BASE_TITLE from "@assets/images/ecosystem/gangwar-title-base.png"
import GANGWAR_RUBY_BG from "@assets/images/ecosystem/ruby-valley.png"
import RUBY_TITLE from "@assets/images/ecosystem/ruby-valley-title.png"
import GANGSTAVERSE_BG from "@assets/images/ecosystem/gangstaverse.png"
import GANGSTAVERSE_BG_TITLE from "@assets/images/ecosystem/gangstaverse-title.png"
import GANGSTAVERSE_DAO_TITLE from "@assets/images/ecosystem/dao-title.png"
import GANGSTABET_BG from "@assets/images/ecosystem/gansgabet-nft.png"
import GANGSTABET_BG_TITLE from "@assets/images/ecosystem/gansgabet-nft-title.png"
import EMERALD_BG from "@assets/images/ecosystem/emarald-city.png"
import EMERALD_BG_TITLE from "@assets/images/ecosystem/emarald-city-title.png"
import ROCK_PAPER_SCISSORS_BG from "@assets/images/ecosystem/rock-paper-scissors.png"
import ROCK_PAPER_SCISSORS_TITLE from "@assets/images/ecosystem/rock-paper-scissors-title.png"
import MULTICHAIN_BG from "@assets/images/ecosystem/multichain-bg.png"
import MULTICHAIN_TITLE from "@assets/images/ecosystem/multichain-title.png"
import GANGSTA_PUGS_BG from "@assets/images/ecosystem/gangstapugs.png"

import TELEGRAM_LINE from "@assets/images/logo/telegram-line.png"

const ECOSYSTEM = {
  GANGWAR_ICON_BG,
  GANGWAR_ICON_TITLE,
  GANGWAR_BASE_BG,
  GANGWAR_BASE_TITLE,
  GANGWAR_RUBY_BG,
  RUBY_TITLE,
  GANGSTAVERSE_BG,
  GANGSTAVERSE_BG_TITLE,
  GANGSTABET_BG,
  GANGSTABET_BG_TITLE,
  EMERALD_BG,
  EMERALD_BG_TITLE,
  ROCK_PAPER_SCISSORS_BG,
  ROCK_PAPER_SCISSORS_TITLE,
  MULTICHAIN_TITLE,
  MULTICHAIN_BG,
  SUI_PLAIN_LOGO,
  ICON_PLAIN_LOGO,
  BASE_PLAIN_LOGO,
  GANGSTAVERSE_DAO_TITLE,
  GANGSTA_PUGS_BG
}

const BANNER = {
  AGORA: agoraBanner,
  GANGSTBET: gangstabetBanner,
  GANGWAR: gangwar,
};

const GANGSTAVERSE = {
  EMERALD_CITY: emeraldCity,
  GANGSTABET: gangstabet,
  GANGWAR: gangwarBanner,
  SAPPHIRE_CITY: sapphireCity,
  DAO: dao,
};

const ILLUSTRATION = {
  NFT: IllustrationSampleNft,
  NFT_PJ: IllustrationSampleNftPJ,
  Layer: TransparentLayer,
};

const PRODUCT = {
  BANK: bank,
  CIVILIANS: civilians,
  GOLDENKEY: goldenKey,
  LOC: loc,
};

const  PARTNERS = {
  ICON_FOUNDATION_PARTNER,
  ICON_CPS_PARTNER,
  CRAFT_PARTNER,
  ANIMOCA_PARTNER,
  TOWER_PARTNER,
  BALANCED_PARTNER
};

const EMERALD_CITY_PRODUCT = {
  LOGO: emeraldCityLogoProduct,
  CROWN: crownLogo,
  SPINNER: spinnerCrown,
  EC_BANNER: ecBanner,
};

const GANGSTABET_PRODUCT = {
  LOGO: gangstabetLogoProduct,
  HAT: hatLogo,
  SPINNER: spinnerHat,
  BANNER: gbBanner,
};

const GANGSTABET_STORY = {
  STORY1: story1,
  STORY2: story2,
  STORY3: story3,
  STORY4: story4,
  STORY_ICON: storyicon,
  DETECTIVE: detective,
};

const TOASTER_ICONS = {
  ERROR: errorIconToaster,
  SUCCESS: successIconToaster,
  WARN: warnIconToaster,
  CLOSE: closeToaster,
};

const MANSION = {
  EMERALD_INFO_BG: EmeraldInfoBg,
  GANGSTER: gangster,
  CAROUSEL: [carouselOne, carouselTwo, carouselThree, carouselFour],
  CAROUSEL_IMAGES: [
    [BottomLeftOne, BottomRightOne, TopRightOne, TopLeftOne],
    [BottomLeftFive, BottomRightFive, TopLeftFive, TopRightFive],
    [BottomLeftTwo, BottomRightTwo, TopRightTwo, TopLeftTwo],
    [BottomLeftThree, BottomRightThree, TopLeftThree, TopRightThree],
    [BottomLeftFour, BottomRightFour, TopLeftFour, TopRightFour],
  ],
  CAROUSEL_NEXT_ICON: CarouselNextIcon,
  CHARACTER_BG1: CharacterBg1,
  CHARACTER_BG2: CharacterBg2,
  SPOTLIGHT: spotlight,
};

const PARALLAX = {
  LAYER1: Layer1,
  LAYER2: Layer2,
  LAYER3: Layer3,
  LAYER4: Layer4,
  LAYER5: Layer5,
};

const FOOTER = {
  FOOTER_BG: FooterBg,
  TWITTER: Twitter,
  DISCORD: Discord,
  TELEGRAM: telegram,
  MEDIUM: medium,
  ICON: icon,
};

const HEADER = {
  BURGER: Burger,
};

const ICONS = {
  ICON: Icon,
  X_ICON,
  DISCORD_ICON,
  X_ICON_WHITE,
  DISCORD_WHITE
};
const LOGOS = {
  GANGSTABET: gangstabetLogo,
  GANGSTAVERSE: gangstaverseLogoAlt,
  GANGSTAVERSE_FULL: gangstaverseLogo,
  GANGSTABET_HAT: gangstabetHat,
  CRAFT: craft,
  AGORA: agoraLogo,
  EMERALD_CITY: emeraldCityLogo,
  SUI_HERO_LOGO,
  ICON_HERO_LOGO,
  SKALE_HERO_LOGO,
  BASE_HERO_LOGO,
  TELEGRAM_LINE,
  ALEO_LOGO
  
};

const LAND = {
  LANDBG: LandBg,
  LANDTOP: landTop,
  LANDMIDDLE: landMiddle,
};

const LAND_VIEWER = LandViewer;

const DECORATOR = {
  DECORATOR_EDGES: Decorator,
  PLAIN_SEPERATOR: plainSep,
  STYLISH_SEPERATOR: stylishSep,
  PLAIN_EDGES: plainDecorEdge,
  VERTICAL_LINE: verticalLine,
  MINT_BOX_SEPERATOR: mintBoxSep,
  MODAL_SEPERATOR: modalSeperator,
};

const TIMELINE = {
  BULLET_POINTS: Bullet,
};

const MINT = {
  ICONBTN: iconbtn,
  LEDGERBTN: ledgerbtn,
  NFTHOLDER: nftholder,
};

export {
  HEADER,
  ICONS,
  LAND,
  FOOTER,
  GANGSTAVERSE,
  LOGOS,
  MANSION,
  LAND_VIEWER,
  DECORATOR,
  PARALLAX,
  TIMELINE,
  EMERALD_CITY_PRODUCT,
  PRODUCT,
  GANGSTABET_PRODUCT,
  GANGSTABET_STORY,
  BANNER,
  gangstaverseText,
  MINT,
  ILLUSTRATION,
  CROWN,
  TOASTER_ICONS,
  gangwarLogo,
  PARTNERS,
  ICON_CHAIN_LOGO,
  BASE_CHAIN_LOGO,
  ECOSYSTEM
};
